import styles from './Groups.module.css';
import GroupItem from "../GroupItem";

const groups = [
    {name: 'Маленька група', col: '8 осіб', price: '3000 грн', priceDesc: 'за 1 предмет за весь період навчання'},
    {name: 'Велика група', col: '26 осіб', price: '1500 грн', priceDesc: 'за 1 предмет за весь період навчання'},
    // {name: 'Маленька група', col: '8 осіб', price: '2800 грн', priceDesc: 'за 1 предмет за весь період навчання'},
    {name: 'Рисунок та композиція', col: '15 осіб', price: '3000 грн', priceDesc: 'за весь період навчання'},
]

export default function Groups() {
    return (
        <div className={styles.groupsWrapper}>
            {
                groups.map((group, i) => {
                    return <GroupItem group={group} key={i}/>
                })
            }
        </div>
    )
}